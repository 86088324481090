import axios from 'axios';

const getAdminToken = () => {
    return localStorage.getItem('adminToken');
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getAdminToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
