
import styles from "./UsersContainer.module.css";
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../utils/config';
import { UserChart } from '../componentsAdmin/UserChart';

export function UsersContainer() {
    const [userStats, setUserStats] = useState({});

    useEffect(() => {
        const fetchUserStats = async () => {
            const response = await axios.post(`${config.authorizationUrl}/api/v1/user_stats`);
            setUserStats(response.data);
        };

        fetchUserStats();
    }, []);

    return (
        <div className={styles['user-container']}>
            <div className={styles['data-container']}>
                <div className={styles['text-container']}>
                    <div> Общее количество пользователей: </div>
                    <div> Пользователи за последний год: </div>
                    <div> Пользователи за последний месяц: </div>
                </div>
                <div className={styles['text-container']}>
                    <div> {userStats.total}</div>
                    <div> {userStats.yearly}</div>
                    <div> {userStats.monthly}</div>
                </div>
            </div>
            <UserChart data={userStats.chartData}/>
        </div>
    );
}


