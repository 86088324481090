import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import styles from './UpdateContainer.module.css';
import config from '../utils/config.json';

export const UpdateContainer = () => {
    const [lastUpdate, setLastUpdate] = useState('Last Update');
    const [updateFrequency, setUpdateFrequency] = useState('');
    const [newFrequency, setNewFrequency] = useState('');

    useEffect(() => {
        fetchUpdateInfo();
    }, []);

    useEffect(() => {
        setNewFrequency(updateFrequency);
    }, [updateFrequency]);

    const fetchUpdateInfo = async () => {
        try {
            const response = await axiosInstance.get(`${config.apiUrl}/updateInfo`);
            setLastUpdate(response.data.lastUpdate);
            setUpdateFrequency(response.data.updateFrequency);
        } catch (error) {
            console.error('Error fetching update info:', error);
        }
    };

    const handleUpdateClick = async () => {
        try {
            await axiosInstance.post(`${config.apiUrl}/update`);
            fetchUpdateInfo();
        } catch (error) {
            console.error('Error updating:', error);
        }
    };

    const handleFrequencyChange = async () => {
        try {
            await axiosInstance.post(`${config.apiUrl}/updateSet`, {
                frequency: newFrequency,
            });
            fetchUpdateInfo();
        } catch (error) {
            console.error('Error setting update frequency:', error);
        }
    };

    return (
        <div className={styles['update-container']}>
            <button className={styles['admin-button']} onClick={handleUpdateClick}>
                UPDATE
            </button>
            <div className={styles['admin-info']}> {lastUpdate}</div>
            <input
                className={styles['date-input']}
                type="text"
                value={newFrequency}
                onChange={(e) => setNewFrequency(e.target.value)}
                placeholder="Frequency (hours)"
            />
            <button className={styles['admin-button']} onClick={handleFrequencyChange}>
                Set Update Frequency
            </button>
        </div>
    );
};
