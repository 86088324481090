import React, { useState } from 'react';
import Select from 'react-select';
import axiosInstance from '../utils/axiosInstance';
import styles from './UpdateTeamsContainer.module.css';
import config from '../utils/config.json';
import selectStyles from '../utils/selectStyles';

export const UpdateTeamsContainer = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [selectedBookmaker, setSelectedBookmaker] = useState('');
    const [field1, setField1] = useState('');
    const [field2, setField2] = useState('');
    const [fileSelected, setFileSelected] = useState(false);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileSelected(true);
    };

    const handleFileNameChange = (e) => {
        setFileName(e.target.value);
    };

    const handleBookmakerChange = (selectedOption) => {
        setSelectedBookmaker(selectedOption.value);
    };

    const handleField1Change = (e) => {
        setField1(e.target.value);
    };

    const handleField2Change = (e) => {
        setField2(e.target.value);
    };

    const handleFileSubmit = () => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const fileContent = event.target.result;

            const logosPath = '/logos/' + fileName + '.png';
            fetch(logosPath, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/png'
                },
                body: fileContent
            }).then(response => {
                if (response.ok) {
                    console.log('File saved to logos directory.');
                } else {
                    console.error('Failed to save file to logos directory.');
                }
            });

            const dataPath = '/mnt/data/logos/' + fileName + '.png';
            fetch(dataPath, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/png'
                },
                body: fileContent
            }).then(response => {
                if (response.ok) {
                    console.log('File saved to data directory.');
                } else {
                    console.error('Failed to save file to data directory.');
                }
            });

            // Обновление состояния
            setFileSelected(false);
            setFileName('');
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const handleDataSubmit = async () => {
        const data = {
            bookmaker: selectedBookmaker,
            field1,
            field2
        };

        try {
            await axiosInstance.post(`${config.apiUrl}/name_comparison`, data);
            alert('Data submitted successfully');
        } catch (error) {
            alert('Data submission failed');
        }
    };

    const bookmakerOptions = [
        { value: 'BetBoom', label: 'BetBoom' },
        { value: 'Liga', label: 'Liga' },
        { value: '1XBet', label: '1XBet' }
    ];

    return (
        <div className={styles['update-teams-container']}>
            <div className={`${styles['file-input-wrapper']} ${fileSelected ? styles['file-selected'] : ''}`}>
                <input className={styles['file-input']} type="file" onChange={handleFileChange}/>
                <label className={styles['file-input-label']}>
                    ▲
                </label>
            </div>
            <input
                className={styles['date-input']}
                type="text"
                value={fileName}
                onChange={handleFileNameChange}
                placeholder="Name"
            />
            <button className={styles['button']} onClick={handleFileSubmit}>
                Send
            </button>

            <Select
                options={bookmakerOptions}
                onChange={handleBookmakerChange}
                className={styles['select']}
                styles={selectStyles}
                placeholder="Bookmaker"
            />

            <input
                className={styles['date-input']}
                type="text"
                value={field1}
                onChange={handleField1Change}
                placeholder="In hltv"
            />
            <input
                className={styles['date-input']}
                type="text"
                value={field2}
                onChange={handleField2Change}
                placeholder="In bookmaker"
            />
            <button className={styles['button']} onClick={handleDataSubmit}>Send</button>
        </div>
    );
};
