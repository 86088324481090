import React from 'react';
import styles from "./AdminPage.module.css";
import { UsersContainer } from "../componentsAdmin/UsersContainer";
import { UpdateContainer } from "../componentsAdmin/UpdateContainer";
import { UpdateTeamsContainer } from '../componentsAdmin/UpdateTeamsContainer';

function AdminPage() {
    return (
        <div className={styles['page']}>
            <div className={styles['general-container']}>

                <UpdateContainer />
                <UpdateTeamsContainer />
                <UsersContainer />
            </div>
        </div>
    );
}

export default AdminPage;