import Select from 'react-select';
import styles from './Selector.module.css';
import selectStyles from '../utils/selectStyles';

const TeamSelector = ({ label, options, onChange, teamClassName, teamImage, value }) => {
    return (
        <div className={`${styles['team-selection']} ${teamClassName}`}>
            <div className={styles['team-logo-container']}>
                <img src={teamImage} alt={label} className={styles['team-logo']} />
            </div>
            <div className={styles['select-container']}>
                <Select
                    options={options}
                    onChange={onChange}
                    className={styles['select']}
                    styles={selectStyles}
                    value={value}
                />
            </div>
        </div>
    );
};

export default TeamSelector;