import { useState, useEffect } from 'react';
import unknownImage from '../logos/unknown.png';

const useTeamImages = (teamName) => {
    const [teamImage, setTeamImage] = useState(unknownImage);

    useEffect(() => {
        const loadImage = async (team) => {
            const imagePath = `/logos/${team}.png`;
            try {
                const response = await fetch(imagePath);
                if (response.ok) {
                    setTeamImage(imagePath);
                } else {
                    setTeamImage(unknownImage);
                }
            } catch (err) {
                setTeamImage(unknownImage);
            }
        };

        loadImage(teamName);
    }, [teamName]);

    return teamImage;
};

export default useTeamImages;
